import React from 'react';
import { Navbar } from 'reactstrap';
import classnames from 'classnames';
// import NavbarBookmarks from "./NavbarBookmarks";
import { Menu } from 'react-feather';
import { connect } from 'react-redux';
import { toggleSidebar } from 'redux/actions/customizer/index';

import NavbarUser from './NavbarUser';
import userImg from '../../../assets/img/portrait/small/avatar-s-11.jpg';
import i18n from '../../../i18n';
import brazilFlag from 'imgs/brazil.png';
import euaFlag from 'imgs/usa.png';

const ThemeNavbar = props => {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
  const { user } = props;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            scrolling: props.horizontal && props.scrolling
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-xl-end justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="d-lg-block d-xl-none">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.toggleSidebarFunc(!props.toggleSidebar)}
                >
                  <Menu size="20px" />
                </div>
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <div>
                <img
                  style={{
                    width: '35px',
                    marginRight: '5px',
                    cursor: 'pointer'
                  }}
                  src={euaFlag}
                  alt=""
                  onClick={() => changeLanguage('en')}
                />
                <img
                  style={{ width: '35px', cursor: 'pointer' }}
                  src={brazilFlag}
                  alt=""
                  onClick={() => changeLanguage('pt')}
                />
              </div>
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={
                  user
                    ? user.role === 'client' ||
                      user.role === 'hms' ||
                      user.role === 'company'
                      ? user.company_name
                      : user.role === 'affiliate'
                      ? user.name
                      : user.full_name
                      ? user.role === 'client_user'
                      : user.client_user
                    : null
                }
                userImg={userImg}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

const mapStateToProps = state => {
  return {
    toggleSidebar: state.customizer.customizer.toggleSidebar,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebarFunc: value => dispatch(toggleSidebar(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar);
