// const axios = require('axios');
import axios from 'axios';
import { history } from '../history';

const instance = axios.create({
  baseURL: 'https://backoffice.pay42.com.br/api',
  // baseURL: 'http://localhost:5008/api',
  // timeout: 5000,
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      history.push('/plataforma/login');
    }
    return Promise.reject(error.response);
  }
);

// module.exports = instance;
export default instance;