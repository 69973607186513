import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/home'
  },
  {
    id: 'employeeHome',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['employee'],
    navLink: '/plataforma/employee/home'
  },
  {
    id: 'activity',
    title: 'Atividade',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/atividade'
  },
  {
    id: 'deposit',
    title: 'Depositar',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/depositar'
  },
  {
    id: 'exchange',
    title: 'Converter',
    type: 'item',
    icon: <Icon.Repeat size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/converter'
  },
  {
    id: 'withdraw',
    title: 'Sacar',
    type: 'item',
    icon: <Icon.Upload size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/sacar'
  },
  {
    id: 'bankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/contas-bancarias'
  },
  // {
  //   id: "settings",
  //   title: "Configurações",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["customer"],
  //   navLink: "/configuracoes"
  // },
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['client', 'client_user'],
    navLink: '/plataforma/clients/home'
  },
  {
    id: 'metrics',
    title: 'Métricas',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/metrics'
  },
  {
    id: 'deposit',
    title: 'Pagamentos',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['client', 'client_user'],
    navLink: '/plataforma/clients/payments'
  },
  {
    id: 'payouts',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['client', 'client_user'],
    navLink: '/plataforma/clients/withdraws'
  },
  {
    id: 'refunds',
    title: 'Estornos',
    type: 'item',
    icon: <Icon.MinusCircle size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/refunds'
  },
  {
    id: 'clientConfig',
    title: 'Configurações',
    type: 'item',
    icon: <Icon.Settings size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/config'
  },
  // {
  //   id: 'import',
  //   title: 'Importar',
  //   type: 'item',
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ['client'],
  //   navLink: '/plataforma/clients/import'
  // },
  // {
  //   id: 'export',
  //   title: 'Exportar',
  //   type: 'item',
  //   icon: <Icon.LogOut size={20} />,
  //   permissions: ['client'],
  //   navLink: '/plataforma/clients/export'
  // },

  // {
  //   id: 'settlements',
  //   title: 'Settlements',
  //   type: 'item',
  //   icon: <Icon.Briefcase size={20} />,
  //   permissions: ['client'],
  //   navLink: '/plataforma/clients/settlements'
  // },
  {
    id: 'clientuser',
    title: 'Usuários',
    type: 'item',
    icon: <Icon.UserPlus size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/client-user'
  },
  // {
  //   id: 'support',
  //   title: 'Support',
  //   type: 'item',
  //   icon: <Icon.MessageCircle size={20} />,
  //   permissions: ['client'],
  //   navLink: '/clients/support',
  // },
  {
    id: 'admHome',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/home'
  },
  {
    id: 'admReports',
    title: 'Relatórios',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/reports'
  },
  {
    id: 'admAccounts',
    title: 'Gerais',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/overall'
  },
  {
    id: 'admInvoices',
    title: 'Notas Fiscais',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/invoices'
  },
  // {
  //   id: 'batchFiles',
  //   title: 'Batch Files',
  //   type: 'item',
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/employee/payouts'
  // },
  {
    id: 'admVolume',
    title: 'Volume',
    type: 'item',
    icon: <Icon.HardDrive size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/volume'
  },
  {
    id: 'admCompliance',
    title: 'KYC',
    type: 'item',
    icon: <Icon.AlertTriangle size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/compliance'
  },
  {
    id: 'admCustomersKyc',
    title: 'Clientes KYC',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/customers-kyc'
  },
  {
    id: 'admExpenses',
    title: 'Despesas',
    type: 'item',
    icon: <Icon.ShoppingCart size={20} />,
    permissions: ['admin', 'expenses'],
    navLink: '/plataforma/admin/expenses'
  },
  {
    id: 'admFeeRates',
    title: 'Taxas',
    type: 'item',
    icon: <Icon.Percent size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/fee-rates'
  },
  {
    id: 'admSettlements',
    title: 'Remessas',
    type: 'item',
    icon: <Icon.Circle size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/settlements'
  },
  {
    id: 'admBalance',
    title: 'Balanço',
    type: 'item',
    icon: <Icon.TrendingUp size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/balance'
  },
  {
    id: 'admProfit',
    title: 'Lucro',
    type: 'item',
    icon: <Icon.Circle size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/profit'
  },
  {
    id: 'admBankAccounts',
    title: 'Contas e Carteiras',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/bank-accounts'
  },
  {
    id: 'admEmployees',
    title: 'Funcionários',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/employees'
  },
  {
    id: 'admClientsBalance',
    title: 'Clientes Saldos',
    type: 'item',
    icon: <Icon.Award size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/clients-balance'
  },
  {
    id: 'admSplit',
    title: 'Split',
    type: 'item',
    icon: <Icon.Divide size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/split'
  },
  {
    id: 'admClient',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserPlus size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/clients'
  },
  {
    id: 'admSubsellers',
    title: 'Subsellers',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/subsellers'
  },
  {
    id: 'admChargebacks',
    title: 'Chargebacks',
    type: 'item',
    icon: <Icon.CornerLeftUp size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/chargebacks'
  },
  {
    id: 'admBatchPayouts',
    title: 'Batch Payouts',
    type: 'item',
    icon: <Icon.Inbox size={20} />,
    permissions: ['batch_payouts_admin'],
    navLink: '/plataforma/admin/batch-payouts'
  },
  {
    id: 'hmsDashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Grid size={20} />,
    permissions: ['hms', 'affiliate'],
    navLink: '/plataforma/hms/'
  },
  {
    id: 'hmsAtividade',
    title: 'Atividade',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['hms', 'affiliate'],
    navLink: '/plataforma/hms/atividade'
  },
  {
    id: 'hmsClientesB2b',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['clients_customers_b2b'],
    navLink: '/plataforma/hms/b2b/clientes'
  },
  {
    id: 'hmsClientesB2c',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['clients_customers_b2c'],
    navLink: '/plataforma/hms/b2c/clientes'
  },
  {
    id: 'hmsAfiliados',
    title: 'Afiliados',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['affiliates'],
    navLink: '/plataforma/hms/afiliados'
  },
  {
    id: 'hmsRecorrencias',
    title: 'Recorrências',
    type: 'item',
    icon: <Icon.RefreshCcw size={20} />,
    permissions: ['recurrency'],
    navLink: '/plataforma/hms/recorrencias'
  },
  {
    id: 'hmsCobrancas',
    title: 'Cobranças',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['charges'],
    navLink: '/plataforma/hms/cobrancas'
  },
  {
    id: 'hmsIncome',
    title: 'Entradas',
    type: 'item',
    icon: <Icon.Pocket size={20} />,
    permissions: ['income'],
    navLink: '/plataforma/hms/income'
  },
  {
    id: 'hmsInvoices',
    title: 'Notas Fiscais',
    type: 'item',
    icon: <Icon.Archive size={20} />,
    permissions: ['invoices'],
    navLink: '/plataforma/hms/invoices'
  },
  {
    id: 'hmsSaque',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Share size={20} />,
    permissions: ['hms', 'client_withdrawal'],
    navLink: '/plataforma/hms/saques'
  },
  {
    id: 'hmsBankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['hms', 'client_withdrawal'],
    navLink: '/plataforma/hms/bank-accounts'
  },
  // {
  //   id: 'hmsTransferencias',
  //   title: 'Transferências',
  //   type: 'item',
  //   icon: <Icon.ArrowRightCircle size={20} />,
  //   permissions: ['affiliates'],
  //   navLink: '/plataforma/hms/transferencias'
  // },
  {
    id: 'hmsAffiliatesReport',
    title: 'Relatórios',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['clients_customers_b2c'],
    navLink: '/plataforma/hms/reports'
  },
  {
    id: 'affiliatesClients',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['hms_affiliates'],
    navLink: '/plataforma/hms/affiliates/clientes'
  },
  {
    id: 'affiliatesRecorrencias',
    title: 'Recorrências',
    type: 'item',
    icon: <Icon.RefreshCcw size={20} />,
    permissions: ['hms_affiliates'],
    navLink: '/plataforma/hms/affiliates/recorrencias'
  },
  {
    id: 'affiliatesCobrancas',
    title: 'Cobranças',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['hms_affiliates'],
    navLink: '/plataforma/hms/affiliates/cobrancas'
  },
  {
    id: 'affiliatesIncome',
    title: 'Entradas',
    type: 'item',
    icon: <Icon.Pocket size={20} />,
    permissions: ['hms_affiliates'],
    navLink: '/plataforma/hms/affiliates/income'
  },
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['ecommerce'],
    navLink: '/plataforma/ecommerce/home'
  },
  {
    id: 'reports',
    title: 'Métricas',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['ecommerce'],
    navLink: '/plataforma/ecommerce/metrics'
  },
  {
    id: 'payments',
    title: 'Pagamentos',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['ecommerce'],
    navLink: '/plataforma/ecommerce/payments'
  },
  {
    id: 'withdraw',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['ecommerce'],
    navLink: '/plataforma/ecommerce/withdraws'
  },
  {
    id: 'EcommerceBankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['ecommerce'],
    navLink: '/plataforma/ecommerce/bank-accounts'
  },
  {
    id: 'EcommerceRefunds',
    title: 'Estornos',
    type: 'item',
    icon: <Icon.CornerUpLeft size={20} />,
    permissions: ['ecommerce', 'admin'],
    navLink: '/plataforma/ecommerce/refunds'
  },
  {
    id: 'companyActivity',
    title: 'Atividade',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/atividade'
  },
  {
    id: 'companySaques',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/saques'
  },
  {
    id: 'companyShareholders',
    title: 'Empresas',
    type: 'item',
    icon: <Icon.Briefcase size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/shareholders'
  },
  {
    id: 'companyBankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/bank-accounts'
  },
  {
    id: 'companyCustomers',
    title: 'Clientes',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/customers'
  },
  {
    id: 'companyCharges',
    title: 'Cobranças',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['company'],
    navLink: '/plataforma/company/charges'
  },
  // {
  //   id: 'chargebacksDefense',
  //   title: 'Chargebacks',
  //   type: 'item',
  //   icon: <Icon.XCircle size={20} />,
  //   permissions: ['chargebacks_defense'],
  //   navLink: '/plataforma/chargebacks-defense'
  // },
  // {
  //   id: 'returnFiles',
  //   title: 'Retorno',
  //   type: 'item',
  //   icon: <Icon.FileText size={20} />,
  //   permissions: ['return_files'],
  //   navLink: '/plataforma/return-files'
  // },
  // {
  //   id: 'deliveryFiles',
  //   title: 'Remessas',
  //   type: 'item',
  //   icon: <Icon.File size={20} />,
  //   permissions: ['delivery_files'],
  //   navLink: '/plataforma/delivery-files'
  // },
  {
    id: 'deliveryFilesExcel',
    title: 'Remessas Excel',
    type: 'item',
    icon: <Icon.File size={20} />,
    permissions: ['delivery_files_excel'],
    navLink: '/plataforma/delivery-files-excel'
  },
  {
    id: 'nfseForm',
    title: 'NFS-e',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    permissions: ['nfse_form'],
    navLink: '/plataforma/nfse-form'
  },
  {
    id: 'batchPayouts',
    title: 'Payouts',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    permissions: ['batch_payouts'],
    navLink: '/plataforma/batch-payouts'
  },
  {
    id: 'internationalPayments',
    title: 'Cabos China',
    type: 'item',
    icon: <Icon.Circle size={20} />,
    permissions: ['international_payments'],
    navLink: '/plataforma/international-payments'
  }
];

export { navigationConfig };
